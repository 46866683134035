import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function DynamicTitleUpdater() {
  const { id: pipelineId } = useParams(); // Get pipeline ID from URL
  const [appTitle, setAppTitle] = useState("Incubator"); // Default title

  useEffect(() => {
    const fetchTitle = async () => {
      if (!pipelineId) {
        console.log("No pipelineId found in the URL.");
        return;
      }

      try {
        // console.log("Fetching pipeline details for ID:", pipelineId);
        const response = await axios.get(
          `https://incubator.drishticps.org/api/pipelines/${pipelineId}`,
          { withCredentials: true }
        );

        if (response.data && response.data.rounds?.length > 0) {
          const firstRound = response.data.rounds[0];
          const newTitle =
            firstRound.applicationFormDesign.applicationTitle || "Incubator";

          setAppTitle(newTitle);
        } else {
          console.log("No rounds found in API response. Using default title.");
          setAppTitle("Incubator");
        }
      } catch (error) {
        console.error("Error fetching application title:", error);
        setAppTitle("Incubator");
      }
    };

    fetchTitle();
  }, [pipelineId]);

  useEffect(() => {
    // console.log("Updating document title:", appTitle);
    document.title = appTitle;
  }, [appTitle]);

  return null;
}

export default DynamicTitleUpdater;

