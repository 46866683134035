
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import { IoHomeOutline } from "react-icons/io5";
import { GrDocumentSound } from "react-icons/gr";
import { IoIosLink } from "react-icons/io";
import { FaSort } from "react-icons/fa";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import { FaColumns, FaFileExcel } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import EditColumnsModal from "./EditColumnsModal";
import "./Applications.css";
import EmailModal from "./EmailModal";
import ConfirmationModal from "./ConfirmationModal";
import { useSelector, useDispatch } from "react-redux";
import {
  superAdminAction,
  userId,
  superAdminSelector,
} from "../../redux/reducers/superAdminReducer";

const Applications = () => {
  const [applications, setApplications] = useState([]);
  const [rounds, setRounds] = useState([]); //storing rounds
  const [currentRoundNumber, setCurrentRoundNumber] = useState(1);
  const [selectedApplications, setSelectedApplications] = useState([]); // Track selected applications
  const [selectAllApplications, setSelectAllApplications] = useState(false); // Track select all checkbox
  const [user, setUser] = useState({ name: "", email: "" });
  const [activeTab, setActiveTab] = useState("Applications");
  const [selectedResponse, setSelectedResponse] = useState(null); // For showing details of a user
  const [selectedUserResponses, setSelectedUserResponses] = useState([]);
  const [activeResponseTab, setActiveResponseTab] = useState(null); // Active response tab
  const [currentPageApplications, setCurrentPageApplications] = useState(1);
  const [responsesPerPageApplications, setResponsesPerPageApplications] =
    useState(20);
  const location = useLocation(); // Get the passed state from Pipeline.jsx
  const { pipelineId, formId, title } = location.state || {}; // Extract pipelineId and formId
  const [nameSortConfig, setNameSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [dateSortConfig, setDateSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  const [comments, setComments] = useState("");
  const [expandedEmails, setExpandedEmails] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filteredStatusApplications, setFilteredStatusApplications] = useState(
    []
  );
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showStatusCountDropdown, setShowStatusCountDropdown] = useState(false);
  const [statusCounts, setStatusCounts] = useState({
    Pending: 0,
    Rejected: 0,
    Selected: 0,
    Hold: 0,
    UnderReview: 0,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector(superAdminSelector);
  const getUserId = useSelector(userId);

  useEffect(() => {
    const fetchRounds = async () => {
      try {
        const response = await axios.get(
          `https://incubator.drishticps.org/api/pipelines/${pipelineId}`,
          { withCredentials: true }
        );
        setRounds(response.data.rounds || []); // Set fetched rounds to state
      } catch (error) {
        // console.error("Error fetching round data:", error);
      }
    };

    fetchRounds(); // Call the fetchRounds function on component load
  }, [pipelineId]);

  const [columns, setColumns] = useState([
    // Initial default columns that will always be visible
    { name: "Name", label: "Applicant", isVisible: true },
    { name: "Email", label: "Email", isVisible: true },
    { name: "currentRound", label: "Round", isVisible: true }, // Round column
    { name: "createdAt", label: "Applied On", isVisible: true },
    { name: "formStatus", label: "Form Status", isVisible: true }, // Added Form Status column
    { name: "status", label: "Review", isVisible: true },
    { name: "Individual", label: "Individual", isVisible: true },
  ]);
  const [allColumns, setAllColumns] = useState([]); // Store all available columns
  const [showModal, setShowModal] = useState(false);

  // Function to handle modal open
  const handleEditColumns = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Save selected columns from modal
  const handleSaveColumns = (selectedColumns) => {
    const updatedColumns = allColumns.map((column) => ({
      ...column,
      isVisible: selectedColumns.includes(column.name),
    }));
    setColumns(updatedColumns); // Set the selected columns as visible
    setShowModal(false);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          "https://incubator.drishticps.org/api/programmanagers/me",
          {
            withCredentials: true,
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    if (getUserId) {
      if (role == "Program Manager") {
        fetchUserData();
      } else if (role == "Admin") {
        navigate("/admincards");
      } else {
        navigate("/cards");
      }
    } else {
      navigate("/login");
    }
  }, []);

  // Fetch applications related to the pipeline and form dynamically
  useEffect(() => {
    const validFormId = Array.isArray(formId)
      ? formId.filter((id) => id !== null)
      : formId;

    if (pipelineId && validFormId && validFormId.length > 0) {
      const fetchApplications = async () => {
        try {
        
          // Fetch data based on the current round
          let response;
          if (currentRoundNumber === 1) {
            /***FOR fetching all responses for Round 1 ***/
            response = await axios.get(
              `/api/forms/pipeline/${pipelineId}/form/${validFormId[0]}/responses/all`, // Fetch all responses for Round 1
              { withCredentials: true }
            );
          } else {
            /*** START CHANGE FOR fetching responses for other rounds ***/
            response = await axios.get(
              `/api/forms/pipeline/${pipelineId}/form/${validFormId[0]}/responses?round=${currentRoundNumber}`, // Fetch responses filtered by the current round
              { withCredentials: true }
            );
          }

          /*** START CHANGE for filtering out "Name" and "Email" columns ***/
          const dynamicColumns = Object.keys(response.data[0].formData || {})
            .filter((key) => key !== "Name" && key !== "Email") // Filter out unwanted columns
            .map((key) => ({
              name: key,
              label: key.charAt(0).toUpperCase() + key.slice(1),
              isVisible: false, // Default to not visible
            }));

          setAllColumns((prevColumns) => [
            ...columns, // Keep the default visible columns
            ...dynamicColumns, // Add dynamic columns
          ]);

          const sortedApplications = response.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setApplications(sortedApplications);
        } catch (err) {
          console.error("Error fetching applications", err);
        }
      };
      fetchApplications();
    } else {
      console.log(
        "pipelineId or validFormId missing.",
        pipelineId,
        validFormId
      );
    }
  }, [pipelineId, formId, currentRoundNumber]);

  const handleSendEmail = async (subject, message) => {
    if (!subject || !message) {
      toast.error("Subject and message cannot be empty.");
      return;
    }

    //  console.log("Selected Applications:", selectedApplications);

    // Extract valid emails
    const selectedEmails = selectedApplications
      .map((app) => {
        // console.log("Checking Applicant:", app);
        const email = app?.formData?.Email?.trim(); // Extract email safely
        // console.log("Extracted Email:", email); // Log extracted email
        return email;
      })
      .filter((email) => email && email.includes("@")); // Remove invalid emails
    // console.log("Final Valid Emails List:", selectedEmails);

    if (selectedEmails.length === 0) {
      toast.error("No valid email addresses found for selected applicants.");
      return;
    }

    try {
      const response = await axios.post(
        "https://incubator.drishticps.org/api/forms/send-email",
        {
          emails: selectedEmails,
          subject,
          message,
        }
      );

      // console.log(" Email API Response:", response.data);
      toast.success("Emails sent successfully!");
      setShowEmailModal(false); // Close modal after sending email
    } catch (error) {
      console.error(
        " Error sending emails:",
        error.response?.data || error.message
      );
      toast.error("Failed to send emails.");
    }
  };

  /***FOR filtering applications by round --- ***/
  const fetchApplicationsForCurrentRound = async () => {
    try {
      let response;

      if (currentRoundNumber === 1) {
        // Fetch all responses for Round 1
        response = await axios.get(
          `/api/forms/pipeline/${pipelineId}/form/${formId[0]}/responses/all`, // for all responses in Round 1
          { withCredentials: true }
        );
      } else {
        // Fetch responses filtered by the current round
        response = await axios.get(
          `/api/forms/pipeline/${pipelineId}/form/${formId[0]}/responses/current?round=${currentRoundNumber}`,
          { withCredentials: true }
        );
      }

      setApplications(response.data); // Update the state with the fetched responses
    } catch (err) {
      console.error("Error fetching applications for current round:", err);
    }
  };

  // Move to Next Stage Button handler
  const handleMoveToNextStage = async () => {
    const selectedApplicantIds = selectedApplications.map((app) => app._id); // Collect selected applicants
    if (selectedApplicantIds.length === 0) {
      toast.error("Please select at least one applicant."); // Show error if no applicants are selected
      return;
    }

    try {
      const response = await axios.post(
        `/api/pipelines/${pipelineId}/rounds/${currentRoundNumber}/move-to-next`,
        { applicantIds: selectedApplicantIds },
        { withCredentials: true }
      );
      toast.success(response.data.message); // Show success message
      setSelectedApplications([]); // Deselect all applications
      setSelectAllApplications(false); // Reset Select All checkbox
      // Refresh the applications for the current round
      fetchApplicationsForCurrentRound();
    } catch (error) {
      // console.error("Error moving applicants:", error);

      if (error.response?.data?.error === "No next round found") {
        toast.error(
          "Next round not found. Please create a next round before moving applicants."
        );
      } else {
        toast.error("Failed to move applicants to the next stage.");
      }
    }
  };
  const handleMoveToPreviousStage = async () => {
    const selectedApplicantIds = selectedApplications.map((app) => app._id); // Collect selected applicants
    if (selectedApplicantIds.length === 0) {
      toast.error("Please select at least one applicant."); // Show error if no applicants are selected
      return;
    }

    if (currentRoundNumber === 1) {
      toast.error("Cannot move applicants back from Round 1."); // Prevent moving back from Round 1
      return;
    }

    try {
      const response = await axios.post(
        `/api/pipelines/${pipelineId}/rounds/${currentRoundNumber}/move-to-previous`,
        { applicantIds: selectedApplicantIds },
        { withCredentials: true }
      );
      toast.success(response.data.message); // Show success message
      setSelectedApplications([]); // Deselect all applications
      setSelectAllApplications(false); // Reset Select All checkbox
      // Refresh the applications for the current round
      fetchApplicationsForCurrentRound();
    } catch (error) {
      console.error("Error moving applicants back:", error);
      toast.error("Failed to move applicants back to the previous stage.");
    }
  };

  // *** START FOR fetching applications correctly based on the round selected ***
  const handleRoundChange = async (roundNumber) => {
    try {
      setCurrentRoundNumber(roundNumber); // Update the current round number immediately
      let response;

      if (roundNumber === 1) {
        // Fetch all responses for Round 1
        response = await axios.get(
          `/api/forms/pipeline/${pipelineId}/form/${formId[0]}/responses/all`, // Endpoint for all responses in Round 1
          { withCredentials: true }
        );
      } else {
        // Fetch responses for the selected round
        response = await axios.get(
          `/api/forms/pipeline/${pipelineId}/form/${formId[0]}/responses/current?round=${roundNumber}`, // Endpoint for responses for specific rounds
          { withCredentials: true }
        );
      }
      // console.log(`API Response for Round ${roundNumber}:`, response.data);

      if (response.data && response.data.length > 0) {
        const sortedApplications = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        // console.log(
        //   `Sorted Applications for Round ${roundNumber}:`,
        //   sortedApplications
        // );

        setApplications(sortedApplications); // Update state with the fetched responses
      } else {
        setApplications([]); // Clear applications if no data is returned
      }
    } catch (err) {
      console.error("Error fetching applications for selected round:", err);
      setApplications([]); // Clear applications in case of an error
    }
  };

  // Update the onClick handler for the round buttons
  {
    rounds.map((round) => (
      <span
        key={round.roundNumber}
        className={`round-name-allapplications ${
          currentRoundNumber === round.roundNumber ? "active-round" : ""
        }`}
        onClick={() => handleRoundChange(round.roundNumber)} // Call the updated handleRoundChange function
      >
        Round {round.roundNumber}
      </span>
    ));
  }

  const toggleEmailExpand = (index) => {
    setExpandedEmails((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const handleNameSort = () => {
    const sortedApplications = [...applications];
    if (nameSortConfig.direction === "asc") {
      sortedApplications.sort((a, b) =>
        a.formData["Name"].localeCompare(b.formData["Name"])
      );
      setNameSortConfig({ key: "Name", direction: "desc" });
    } else {
      sortedApplications.sort((a, b) =>
        b.formData["Name"].localeCompare(a.formData["Name"])
      );
      setNameSortConfig({ key: "Name", direction: "asc" });
    }
    setApplications(sortedApplications);
  };
  // Function to handle date and time sorting
  const handleDateSort = () => {
    const sortedApplications = [...applications];
    if (dateSortConfig.direction === "asc") {
      sortedApplications.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      setDateSortConfig({ key: "Applied On", direction: "desc" });
    } else {
      sortedApplications.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setDateSortConfig({ key: "Applied On", direction: "asc" });
    }
    setApplications(sortedApplications);
  };
  // **Filter Applications based on Date Range**
  useEffect(() => {
    if (!fromDate || !toDate) {
      setFilteredApplications(applications); //Reset to all applications if no date range is selected
      return;
    }

    const from = new Date(fromDate).setHours(0, 0, 0, 0);
    const to = new Date(toDate).setHours(23, 59, 59, 999);

    let filtered = applications.filter((app) => {
      const appliedDate = new Date(app.createdAt).setHours(0, 0, 0, 0);
      return appliedDate >= from && appliedDate <= to;
    });

    setFilteredApplications(filtered);
  }, [fromDate, toDate, applications]);

  const handleLogout = async () => {
    const response = await axios.post(
      `https://incubator.drishticps.org/api/logout/programManager/${user._id}`,
      {},
      { withCredentials: true }
    );
    setUser(null);
    dispatch(superAdminAction.logoutUser());
    navigate("/login");
  };

  // Helper function to format the date and time
  const formatDateAndTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(); // Get only the date
    const formattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }); // Get the time in HH:MM format
    return { formattedDate, formattedTime };
  };

  const handleViewDetails = async (response) => {
    try {
      const res = await axios.get(
        `/api/forms/pipeline/${response.pipelineId}/user/${response.formData.Email}/responses`,
        { withCredentials: true }
      );

      // console.log("API Response for User Details:", res.data);

      if (res.data && res.data.length > 0) {
        const sortedResponses = res.data.map((resp) => {
          // console.log("Checking formData:", resp.formData);
          return {
            ...resp,
            formData: resp.formData || {}, // Provide a fallback for missing data
          };
        });
        setSelectedUserResponses(sortedResponses);
        setActiveResponseTab(response.currentRound);
        setSelectedResponse(response);
      } else {
        toast.error("No responses available for this user.");
      }
    } catch (error) {
      console.error("Error fetching user responses:", error);
      toast.error("Failed to fetch user responses.");
    }
  };

  const handleBackToApplications = () => {
    setSelectedResponse(null); // Go back to applications list
  };

  // Handle selecting all applications
  const handleSelectAllApplications = () => {
    if (selectAllApplications) {
      setSelectedApplications([]); // Deselect all
    } else {
      setSelectedApplications(applications); // Select all
    }
    setSelectAllApplications(!selectAllApplications); // Toggle select all checkbox
  };

  // Handle selecting individual applications

  const handleSelectApplication = (application) => {
    // console.log("Selected Applicant:", application);
    // console.log("Applicant Email:", application.email);
    const isSelected = selectedApplications.some(
      (app) => app._id === application._id
    );

    if (isSelected) {
      // Deselect the application
      setSelectedApplications(
        selectedApplications.filter((a) => a._id !== application._id)
      );
    } else {
      // Select the application
      setSelectedApplications([...selectedApplications, application]);
    }
  };

  const handleStatusChange = (submissionId, newStatus) => {
    setSelectedSubmission(submissionId); // Store selected submission ID
    setNewStatus(newStatus); // Store new status
    setShowConfirmationModal(true); // Open confirmation modal
  };

  //Handles Confirm Status Change
  const handleConfirmStatusChange = async () => {
    try {
      const updateData = { status: newStatus };

      if (
        newStatus === "Rejected" ||
        newStatus === "Selected" ||
        newStatus === "Hold" ||
        newStatus === "UnderReview"
      ) {
        updateData.comments = comments || ""; // Add comments
      }

      const response = await axios.put(
        `/api/forms/form-submission/${selectedSubmission}/status`,
        updateData,
        { withCredentials: true }
      );

      toast.success(response.data.message);

      //Update applications state
      setApplications((prevApplications) =>
        prevApplications.map((app) =>
          app._id === selectedSubmission
            ? { ...app, status: newStatus, comments: updateData.comments }
            : app
        )
      );

       setShowConfirmationModal(false);
      setComments(""); // Clear comments input
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update Review.");
    }
  };
  //cancel status change for ConfirmationModal.jsx
  const handleCancelStatusChange = () => {
    setShowConfirmationModal(false); // Close modal
    setComments(""); // Clear input when closing modal
    toast.info("Status change canceled.");
  };

  useEffect(() => {
    setSelectAllApplications(
      selectedApplications.length === applications.length
    );
  }, [selectedApplications, applications]);

  const handleRowsPerPageChangeApplications = (e) => {
    setResponsesPerPageApplications(Number(e.target.value));
    setCurrentPageApplications(1);
  };

  const handlePageChangeApplications = (page) => {
    if (page > 0 && page <= totalPagesApplications) {
      setCurrentPageApplications(page);
    }
  };

  const indexOfLastResponseApplications =
    currentPageApplications * responsesPerPageApplications;
  const indexOfFirstResponseApplications =
    indexOfLastResponseApplications - responsesPerPageApplications;

  const currentResponsesApplications = filteredApplications.slice(
    indexOfFirstResponseApplications,
    indexOfLastResponseApplications
  );

  const totalPagesApplications = Math.ceil(
    filteredApplications.length / responsesPerPageApplications
  );

  const handleExportToExcel = () => {
    if (selectedApplications.length === 0) {
      toast.error("Please select at least one row to export.");
      return;
    }

    // Function to remove HTML tags
    const removeHtmlTags = (str) => {
      if (typeof str === "string") {
        return str.replace(/<[^>]*>/g, ""); // Remove all HTML tags
      }
      return str;
    };

    // Extract visible columns (preserve order)
    const visibleColumns = columns
      .filter((col) => col.isVisible)
      .map((col) => col.name);

    // Prepare data for Excel export
    const excelData = selectedApplications.map((app) => {
      const rowData = {};

      // Include Object ID
    rowData["Object ID"] = app._id; // Added Object ID

      // Include visible columns in the same order as the UI
      visibleColumns.forEach((colName) => {
        if (colName === "createdAt") {
          rowData["Applied On"] = new Date(app.createdAt).toLocaleString();
        } else if (colName === "currentRound") {
          rowData["Round"] = `Round ${app.currentRound}`;
        } else if (colName === "status") {
          rowData["Review"] = app.status;
        } else if (colName === "formStatus") {
          rowData["Form Status"] = app.formStatus || "N/A";
        } else if (app.formData[colName]) {
          rowData[colName] = app.formData[colName]; // Add form data fields
        }
      });

      // Include **all** form responses dynamically (View Details section)
      Object.keys(app.formData).forEach((key) => {
        if (!rowData[key]) {
          // rowData[key] = app.formData[key]; // Ensures every field is included
          rowData[key] = removeHtmlTags(app.formData[key]); // Ensures every field is included without HTML tags
        }
      });

      // Handle File Attachments (Maintain Proper Column Order)
      if (app.files && app.files.length > 0) {
        app.files.forEach((file, index) => {
          rowData[`File ${index + 1}`] = file.path ? file.path : "N/A"; // Ensure proper file linking
        });
      } else {
        rowData["File 1"] = "N/A";
        rowData["File 2"] = "N/A";
        rowData["File 3"] = "N/A";
      }

      return rowData;
    });

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(excelData);

    // Ensure hyperlinks work correctly
    Object.keys(worksheet).forEach((cell) => {
      if (worksheet[cell].v && worksheet[cell].v.startsWith("http")) {
        worksheet[cell].l = { Target: worksheet[cell].v }; // Apply proper hyperlink formatting
      }
    });

    // Auto-adjust column width for better readability
    const columnWidths = Object.keys(excelData[0] || {}).map((key) => ({
      wch: Math.max(15, key.length + 5),
    }));
    worksheet["!cols"] = columnWidths;

    // Create workbook and append worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Applications");

    // Download as .xlsx file
    XLSX.writeFile(workbook, "applications_responses.xlsx");
  };

  const handleExportCurrentPageToExcel = () => {
    if (currentResponsesApplications.length === 0) {
      toast.error("No applications to export on this page.");
      return;
    }

    // Function to remove HTML tags
    const removeHtmlTags = (str) => {
      if (typeof str === "string") {
        return str.replace(/<[^>]*>/g, ""); // Remove all HTML tags
      }
      return str;
    };

    // Extract visible columns (preserve order)
    const visibleColumns = columns
      .filter((col) => col.isVisible)
      .map((col) => col.name);

    // Prepare data for Excel export (for current page only)
    const excelData = currentResponsesApplications.map((app) => {
      const rowData = {};

      // Include Object ID
    rowData["Object ID"] = app._id; // Added Object ID

      // Include visible columns in the same order as the UI
      visibleColumns.forEach((colName) => {
        if (colName === "createdAt") {
          rowData["Applied On"] = new Date(app.createdAt).toLocaleString();
        } else if (colName === "currentRound") {
          rowData["Round"] = `Round ${app.currentRound}`;
        } else if (colName === "status") {
          rowData["Review"] = app.status;
        } else if (colName === "formStatus") {
          rowData["Form Status"] = app.formStatus || "N/A";
        } else if (app.formData[colName]) {
          rowData[colName] = app.formData[colName]; // Add form data fields
        }
      });

      // Include all form responses dynamically (View Details section)
      Object.keys(app.formData).forEach((key) => {
        if (!rowData[key]) {
          rowData[key] = removeHtmlTags(app.formData[key]); // Include without HTML tags
        }
      });

      // Handle File Attachments (Maintain Proper Column Order)
      if (app.files && app.files.length > 0) {
        app.files.forEach((file, index) => {
          rowData[`File ${index + 1}`] = file.path ? file.path : "N/A";
        });
      } else {
        rowData["File 1"] = "N/A";
        rowData["File 2"] = "N/A";
        rowData["File 3"] = "N/A";
      }

      return rowData;
    });

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(excelData);

    // Ensure hyperlinks work correctly
    Object.keys(worksheet).forEach((cell) => {
      if (worksheet[cell].v && worksheet[cell].v.startsWith("http")) {
        worksheet[cell].l = { Target: worksheet[cell].v }; // Add hyperlink formatting
      }
    });

    // Auto-adjust column width
    const columnWidths = Object.keys(excelData[0] || {}).map((key) => ({
      wch: Math.max(15, key.length + 5),
    }));
    worksheet["!cols"] = columnWidths;

    // Create workbook and append worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Current Page Applications"
    );

    // Download the Excel file
    XLSX.writeFile(workbook, "current_page_applications.xlsx");
  };

  useEffect(() => {
    let searchedApplications = applications;

    if (searchTerm.trim() !== "") {
      searchedApplications = applications.filter((app) =>
        Object.values(app.formData || {}).some((value) =>
          value?.toString()?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    setFilteredApplications(searchedApplications); // Apply search filter first
    setCurrentPageApplications(1); // Reset to first page on new search
  }, [searchTerm, applications]);

  useEffect(() => {
    if (pipelineId && formId && formId.length > 0) {
      fetchStatusCounts();
    }
  }, [pipelineId, formId]);

  const fetchStatusCounts = async () => {
    try {
      const response = await axios.get(
        `https://incubator.drishticps.org/api/forms/pipeline/${pipelineId}/form/${formId[0]}/status-count`,
        { withCredentials: true }
      );
      setStatusCounts(response.data);
    } catch (error) {
      console.error("Error fetching status counts:", error);
    }
  };

  const statusCountRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        statusCountRef.current &&
        !statusCountRef.current.contains(event.target)
      ) {
        setShowStatusCountDropdown(false); // Close dropdown if click is outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup on unmount
    };
  }, []);

  const handleFileDownload = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to handle PDF download of Applicant Form Details (excluding status, documents, email) with multi-page support

  const stripHTMLTags = (text) => {
    if (!text) return "";
    return text.replace(/<[^>]*>/g, "").trim(); // Removes all HTML tags
  };

  const handleDownloadPDF = (response) => {
    const doc = new jsPDF();
    const formData = response?.formData || {};

    doc.setFontSize(18);
    doc.text("Applicant Form Details", 105, 15, { align: "center" });

    // Excluded fields
    const excludedKeys = ["Status", "Documents"];

    // Prepare the table data
    const tableData = Object.entries(formData)
      .filter(([key]) => !excludedKeys.includes(key))
      .map(([key, value], index) => [
        { content: index + 1, styles: { fontStyle: "bold", halign: "center" } },
        { content: key, styles: { fontStyle: "bold" } },
        {
          content: stripHTMLTags(
            typeof value === "string" ? value : JSON.stringify(value)
          ),
        },
      ]);

    // Check for empty data
    if (tableData.length === 0) {
      doc.setFontSize(12);
      doc.setTextColor(0, 0, 0);
      doc.text("No Applicant Form Details available.", 20, 40);
    } else {
      doc.autoTable({
        head: [["#", "Field", "Value"]],
        body: tableData,
        startY: 30,
        theme: "grid",
        styles: {
          fontSize: 11,
          cellPadding: 4,
          overflow: "linebreak",
          halign: "left",
          valign: "middle",
        },
        headStyles: {
          fillColor: [1, 131, 255],
          textColor: [255, 255, 255],
          fontSize: 12,
          fontStyle: "bold",
        },
        bodyStyles: {
          textColor: [50, 50, 50],
        },
        alternateRowStyles: {
          fillColor: [240, 248, 255], // Light blue for alternate rows
        },
        columnStyles: {
          0: { cellWidth: 15 }, // Serial Number column
          1: { cellWidth: 60 }, // Field column
          2: { cellWidth: 110 }, // Value column
        },
        margin: { top: 35 },
      });
    }

    // Save the PDF with applicant's name or default name
    const fileName = formData?.Name
      ? `${formData.Name.replace(/\s+/g, "_")}_Applicant_Form_Details.pdf`
      : "Applicant_Form_Details.pdf";

    doc.save(fileName);
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const formatUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };
  const createMarkup = (html) => {
    return { __html: html };
  };
  const sanitizeResponse = (htmlString) => {
    return htmlString.replace(/<p>|<\/p>/g, "");
  };
  const renderResponseTabs = () => {
    if (!selectedUserResponses || selectedUserResponses.length === 0) {
      console.warn("No responses found for the selected user.");
      return <p>No responses found.</p>;
    }
    return (
      <div>
        <div className="response-details-applications">
          <div className="header-with-button-applications">
            <h4 style={{ color: "white" }}></h4>
            <div className="button-group-allapplications">
              <button
                className="below-tab-button-applications"
                onClick={() => window.print()}
              >
                Print Response
              </button>
              <button
                className="below-tab-button-applications"
                onClick={() => handleDownloadPDF(selectedResponse)}
              >
                Download PDF
              </button>
              <button
                className="below-tab-button-applications"
                onClick={handleBackToApplications}
              >
                Back to Applications
              </button>
            </div>
          </div>
          <div className="tab-buttons-allapplications">
            {rounds.map((round) => (
              <button
                key={round.roundNumber}
                className={`tab-button-allapplications ${
                  activeResponseTab === round.roundNumber ? "active" : ""
                }`}
                onClick={() => {
                  console.log(`Switching to Round ${round.roundNumber}`);
                  setActiveResponseTab(round.roundNumber);
                }}
              >
                Round {round.roundNumber} Response
              </button>
            ))}
          </div>

          <div className="custom-response-details-applications">
            {selectedUserResponses.some(
              (resp) => resp.roundNumber === activeResponseTab
            ) ? (
              selectedUserResponses

                .filter((resp) => resp.roundNumber === activeResponseTab)

                .map((resp, idx) => (
                  <div key={idx}>
                    <div style={{ marginTop: "20px" }}>
                      <h4 className="documents-heading-allapplications">
                        Applicant Form Details
                      </h4>

                      {resp.formData &&
                      Object.keys(resp.formData).length > 0 ? (
                        Object.keys(resp.formData).map((key, index) => {
                          const value = resp.formData[key];

                          // Find the actual file path in the `files` array
                          const matchedFile = resp.files?.find(
                            (file) =>
                              file.originalName === String(value).split(" (")[0]
                          );

                          // Check if the value is a valid file name with an extension
                          const isFile =
                            matchedFile &&
                            /\.(pdf|png|jpg|jpeg|gif|bmp|tiff|webp|svg|doc|docx|odt|rtf|txt|ppt|pptx|pps|ppsx|xls|xlsx|csv|zip|rar|7z|tar|gz|mp4|mkv|avi|mov|wmv|flv|webm|mp3|wav|aac|ogg|flac|wma|apk|exe|msi|dmg|iso|bin|json|xml|html|css|js|jsx|ts|tsx|py|java|cpp|c|cs|go|swift|php|rb|sh|bat|sql|md)$/i.test(
                              matchedFile.originalName
                            );

                          // **Check if value contains HTML tags (React Quill content)**
                          const isRichText = /<\/?[a-z][\s\S]*>/i.test(value);

                          return (
                            <div
                              key={index}
                              className="response-item-applications"
                            >
                              <div className="number-box-allapplications">
                                {index + 1}
                              </div>
                              <div className="response-key-top-allapplications">
                                <h5 className="response-key-allapplications">
                                  {key} :
                                </h5>

                                {isFile ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    {/* File Name with Clickable Link */}
                                    {/* <a
                                      href={matchedFile.path}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="download-side-file-response-allapplications"
                                    > */}
                                    {/* Display the actual file name */}
                                    {matchedFile.originalName} (uploaded at{" "}
                                    {moment(matchedFile.uploadedAt).format(
                                      "DD/MM/YYYY hh:mm A"
                                    )}
                                    ){/* </a> */}
                                    <button
                                      className="download-file-button-applications"
                                      onClick={() =>
                                        handleFileDownload(
                                          matchedFile.path,
                                          matchedFile.originalName
                                        )
                                      }
                                    >
                                      Download
                                    </button>
                                  </div>
                                ) : isRichText ? (
                                  <div
                                    className="response-value-allapplications"
                                    dangerouslySetInnerHTML={{ __html: value }}
                                  ></div>
                                ) : (
                                  <p className="response-value-allapplications">
                                    {value}
                                  </p>
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="no-response-allapplications">
                          No Applicant Details available.
                        </p>
                      )}
                    </div>

                    <hr />

                    {/* Documents Section */}
                    <div className="documents-section-allapplications">
                      <h4 className="documents-heading-allapplications">
                        Documents
                      </h4>
                      {resp.files && resp.files.length > 0 ? (
                        <div className="documents-data-section-allapplications">
                          {resp.files.map((file, index) => (
                            <div
                              key={index}
                              className="file-item-allapplications"
                              style={{ width: "100%" }}
                            >
                              <div
                                className="number-box-allapplications"
                                style={{ width: "2%" }}
                              >
                                {index + 1}
                              </div>
                              <div className="file-details-allapplications">
                                <h5 className="response-key-document-allapplications">
                                  {file.labelName}{" "}
                                  <span className="response-value-file-allapplications">
                                    uploaded at:{" "}
                                    <span className="uploaded-time-allapplications">
                                      {moment(file.uploadedAt).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </span>
                                  </span>
                                </h5>
                                <a
                                  href={`${file.path}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="file-response-allapplications"
                                >
                                  {file.originalName}
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="no-response-allapplications">
                          No documents uploaded yet.
                        </p>
                      )}
                    </div>

                    <hr />
                    
                    {/* Form Status Details */}
                    <div className="form-timing-section-allapplications">
                      <h4 className="documents-heading-allapplications">
                        Form Status Details
                      </h4>

                      <div className="response-item-applications">
                        <div className="number-box-allapplications">1</div>
                        <div className="response-key-top-allapplications">
                          <h5 className="response-key-allapplications">
                            Form Review Status :
                          </h5>
                          <p className="response-value-allapplications">
                            {resp.status ? resp.status : "Pending"}
                          </p>
                        </div>
                      </div>

                      <div className="response-item-applications">
                        <div className="number-box-allapplications">2</div>
                        <div className="response-key-top-allapplications">
                          <h5 className="response-key-allapplications">
                            Comments :
                          </h5>
                          <p className="response-value-allapplications">
                            {resp.comments ? resp.comments : "No comment yet"}
                          </p>
                        </div>
                      </div>

                      <div className="response-item-applications">
                        <div className="number-box-allapplications">3</div>
                        <div className="response-key-top-allapplications">
                          <h5 className="response-key-allapplications">
                            Form Status :
                          </h5>
                          <p className="response-value-allapplications">
                            {resp.formStatus || "N/A"}
                          </p>
                        </div>
                      </div>
                      <div className="response-item-applications">
                        <div className="number-box-allapplications">4</div>
                        <div className="response-key-top-allapplications">
                          <h5 className="response-key-allapplications">
                            Form First Saved Time :
                          </h5>
                          <p className="response-value-allapplications">
                            {resp.formFirstSavedTime
                              ? new Date(
                                  resp.formFirstSavedTime
                                ).toLocaleString()
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                      <div className="response-item-applications">
                        <div className="number-box-allapplications">5</div>
                        <div className="response-key-top-allapplications">
                          <h5 className="response-key-allapplications">
                            Last Modified :
                          </h5>
                          <p className="response-value-allapplications">
                            {resp.lastModified
                              ? new Date(resp.lastModified).toLocaleString()
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                      <div className="response-item-applications">
                        <div className="number-box-allapplications">6</div>
                        <div className="response-key-top-allapplications">
                          <h5 className="response-key-allapplications">
                            Form Submission Time :
                          </h5>
                          <p className="response-value-allapplications">
                            {resp.formSubmissionTime
                              ? new Date(
                                  resp.formSubmissionTime
                                ).toLocaleString()
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                      <div className="response-item-applications">
                        <div className="number-box-allapplications">7</div>
                        <div className="response-key-top-allapplications">
                          <h5 className="response-key-allapplications">
                            Object ID :
                          </h5>
                          <p className="response-value-allapplications">
                            {resp._id || "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className="email-history-section-allapplications">
                      <h4 className="documents-heading-allapplications">
                        Email History
                      </h4>
                      {selectedUserResponses.some(
                        (resp) => resp.roundNumber === activeResponseTab
                      ) ? (
                        selectedUserResponses
                          .filter(
                            (resp) => resp.roundNumber === activeResponseTab
                          )
                          .map((resp, idx) => (
                            <div key={idx}>
                              {resp.emailHistory &&
                              resp.emailHistory.length > 0 ? (
                                <div className="email-list-allapplications-email-history">
                                  {resp.emailHistory.map((email, index) => (
                                    <div
                                      key={index}
                                      className="email-item-allapplications-email-history"
                                    >
                                      <div
                                        className="email-header-allapplications-email-history"
                                        onClick={() => toggleEmailExpand(index)}
                                      >
                                        <div className="email-header-left-allapplications-email-history">
                                          <span className="email-subject-allapplications-email-history">
                                            {email.subject}
                                          </span>
                                        </div>
                                        <span className="email-timestamp-allapplications-email-history">
                                          {moment(email.sentAt).format(
                                            "DD/MM/YYYY hh:mm A"
                                          )}
                                        </span>
                                      </div>
                                      {expandedEmails[index] && (
                                        <div className="email-body-allapplications-email-history">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: email.message,
                                            }}
                                          ></p>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <p className="no-response-allapplications">
                                  No email history available.
                                </p>
                              )}
                            </div>
                          ))
                      ) : (
                        <p className="no-response-allapplications">
                          No response available for this round.
                        </p>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <p style={{ marginTop: "20px" }}>
                No response available for this round.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    if (selectedResponse) {
      return renderResponseTabs();
    }
    switch (activeTab) {
      case "Applications":
        const filteredApplications = applications.filter((app) =>
          Object.values(app.formData || {}).some((value) =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        );

        return (
          <div>
            <div className="search-container-allapplications">
              <div className="search-input-wrapper-allapplications">
                <AiOutlineSearch className="search-icon-allapplications" />

                <input
                  type="text"
                  placeholder="Search applicant by name"
                  className="search-input-allapplications"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            {applications.length === 0 ? (
              <div>.</div>
            ) : (
              <div className="applications-table-allapplications">
                <div className="custom-response-header-allapplications">
                  <h4
                    className="custom-print-title"
                    style={{ color: "white" }}
                  ></h4>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div className="date-filter-container-allapplications">
                      <label className="date-label-allapplications">
                        From:
                      </label>
                      <input
                        type="date"
                        className="date-input-allapplications"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        title="Select the start date to filter applications from this date onwards"
                      />
                      <label className="date-label-allapplications">To:</label>
                      <input
                        type="date"
                        className="date-input-allapplications"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        title="Select the end date to filter applications up to this date"
                      />

                      <div className="status-dropdown-container-allapplications">
                        <button
                          className="filter-by-Status-applications"
                          onClick={() =>
                            setShowStatusDropdown(!showStatusDropdown)
                          }
                        >
                          Filter by Status
                        </button>

                        {showStatusDropdown && (
                          <div className="status-options-dropdown-allapplications">
                            {[
                              "Pending",
                              "Selected",
                              "Rejected",
                              "Hold",
                              "Under Review",
                            ].map((status) => (
                              <div
                                key={status}
                                className="status-option-allapplications"
                                onClick={() => {
                                  setSelectedStatus(status);
                                  const filtered = applications.filter(
                                    (app) =>
                                      app.status
                                        .toLowerCase()
                                        .replace(/\s/g, "") ===
                                      status.toLowerCase().replace(/\s/g, "")
                                  );
                                  setFilteredApplications(filtered);
                                  setShowStatusDropdown(false);
                                }}
                              >
                                {status}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="status-count-container-applications"
                      ref={statusCountRef}
                    >
                      <button
                        className="status-count-button-applications"
                        onClick={() =>
                          setShowStatusCountDropdown(!showStatusCountDropdown)
                        }
                        title="Show Status Counts"
                      >
                        Counts
                      </button>

                      {showStatusCountDropdown && (
                        <div className="status-count-dropdown-applications">
                          {Object.entries(statusCounts).map(
                            ([status, count]) => (
                              <div
                                key={status}
                                className="status-count-item-applications"
                              >
                                <span>{status}:</span>
                                <span>{count}</span>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>

                    <button
                      onClick={() => {
                        if (selectedApplications.length > 0) {
                          setShowEmailModal(true); // Open the modal
                        } else {
                          toast.error("Please select at least one applicant.");
                        }
                      }}
                      className="below-tab-button-applications"
                      title="Send an email to the applicants selected via checkboxes"
                    >
                      <span
                        style={{
                          marginRight: "6px",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        <FaEnvelope
                          style={{
                            fontWeight: "200",
                            fontSize: "12px",
                            color: "#17a2b8",
                          }}
                        />
                      </span>
                      Send Custom Email
                    </button>

                    <button
                      className="below-tab-button-applications"
                      onClick={handleExportToExcel}
                      title="Download all applicant data as an Excel file"
                    >
                      <span
                        style={{
                          marginRight: "6px",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        <FaFileExcel
                          style={{
                            fontWeight: "200",
                            fontSize: "12px",
                            color: "#28a745",
                          }}
                        />
                      </span>
                      Export to Excel
                    </button>

                    <button
                      onClick={handleMoveToNextStage}
                      className="below-tab-button-applications"
                      title="Move selected applications to the next stage"
                    >
                      <span
                        style={{
                          marginRight: "6px",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        <FaArrowRight
                          style={{
                            fontWeight: "200",
                            fontSize: "12px",
                            color: "#007bff",
                          }}
                        />
                      </span>
                      Next Stage
                    </button>
                    <button
                      onClick={handleMoveToPreviousStage}
                      className="below-tab-button-applications"
                      title="Move selected applications to the previous stage"
                    >
                      <span
                        style={{
                          marginRight: "6px",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        <FaArrowLeft
                          style={{
                            fontWeight: "200",
                            fontSize: "12px",
                            color: "#f08080",
                          }}
                        />
                      </span>
                      Previous Stage
                    </button>

                    <button
                      onClick={handleEditColumns}
                      className="below-tab-button-applications"
                      title="Edit the visible columns in the table"
                    >
                      <span
                        style={{
                          marginRight: "6px",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        <FaColumns
                          style={{
                            fontWeight: "200",
                            fontSize: "12px",
                            color: "#6c757d",
                          }}
                        />
                      </span>
                      Edit Columns
                    </button>
                  </div>
                </div>
                <table style={{ marginTop: "5px" }}>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={handleSelectAllApplications}
                          checked={selectAllApplications}
                        />
                      </th>
                      {columns
                        .filter((col) => col.isVisible)
                        .map((col) => (
                          <th key={col.name}>
                            {col.label}
                            {col.name === "Name" && (
                              <FaSort
                                className="sorticon-applications"
                                onClick={handleNameSort}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                            {col.name === "createdAt" && (
                              <FaSort
                                className="sorticon-applications"
                                onClick={handleDateSort}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentResponsesApplications.map((app, index) => {
                      {
                        /* {filteredApplications.map((app, index) => { */
                      }
                      const { formattedDate, formattedTime } =
                        formatDateAndTime(app.createdAt);
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedApplications.includes(app)}
                                onChange={() => handleSelectApplication(app)}
                              />
                            </td>
                            {columns
                              .filter((col) => col.isVisible)
                              .map((col) => (
                                <td
                                  key={col.name}
                                  data-tooltip={
                                    col.name === "Name" || col.name === "Email"
                                      ? app.formData[col.name] || "N/A"
                                      : ""
                                  }
                                  style={{
                                    maxWidth:
                                      col.name === "Name" ||
                                      col.name === "Email"
                                        ? "180px"
                                        : "auto",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    position: "relative",
                                  }}
                                >
                                  {col.name === "createdAt" ? (
                                    `${
                                      formatDateAndTime(app.createdAt)
                                        .formattedDate
                                    } ${
                                      formatDateAndTime(app.createdAt)
                                        .formattedTime
                                    }`
                                  ) : col.name === "currentRound" ? (
                                    `Round ${app.currentRound}` // Populate Round column
                                  ) : col.name === "Individual" ? (
                                    <button
                                      className="view-details-button-applications"
                                      onClick={() => handleViewDetails(app)}
                                    >
                                      View Details
                                    </button>
                                  ) : col.name === "status" ? (
                                    <>
                                      <div className="status-dropdown-container">
                                        <select
                                          value={app.status}
                                          onChange={(e) =>
                                            handleStatusChange(
                                              app._id,
                                              e.target.value
                                            )
                                          }
                                          className={`status-dropdown-allapplications ${app.status.toLowerCase()}`}
                                        >
                                          <option value="Pending">
                                            Pending
                                          </option>
                                          <option value="Selected">
                                            Selected
                                          </option>
                                          <option value="Rejected">
                                            Rejected
                                          </option>
                                          <option value="Hold">Hold</option>
                                          <option value="UnderReview">
                                            Under Review
                                          </option>
                                        </select>
                                      </div>
                                    </>
                                  ) : col.name === "formStatus" ? (
                                    app.formStatus
                                  ) : col.name === "Name" ||
                                    col.name === "Email" ? (
                                    <span
                                      title={app.formData[col.name] || "N/A"}
                                    >
                                      {app.formData[col.name] || "N/A"}
                                    </span>
                                  ) : (
                                    app.formData[col.name] || "N/A"
                                  )}
                                </td>
                              ))}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <EmailModal
                  show={showEmailModal}
                  onClose={() => setShowEmailModal(false)}
                  selectedApplications={selectedApplications || []} // an array
                  onSendEmail={handleSendEmail} // Pass the function as a prop
                />

                <div className="pagination-container-applications">
                  <div className="pagination-applications">
                    <button
                      className={`pagination-arrow-applications ${
                        currentPageApplications === 1 && "disabled"
                      }`}
                      onClick={() =>
                        handlePageChangeApplications(
                          currentPageApplications - 1
                        )
                      }
                    >
                      &lt;
                    </button>
                    <span className="page-number-applications">
                      <span className="current-page-applications">
                        {currentPageApplications}
                      </span>{" "}
                      / {totalPagesApplications}
                    </span>
                    <button
                      className={`pagination-arrow-applications ${
                        currentPageApplications === totalPagesApplications &&
                        "disabled"
                      }`}
                      onClick={() =>
                        handlePageChangeApplications(
                          currentPageApplications + 1
                        )
                      }
                    >
                      &gt;
                    </button>
                  </div>
                  <div className="rows-info-applications">
                    <span>
                      Showing {indexOfFirstResponseApplications + 1} -{" "}
                      {Math.min(
                        indexOfLastResponseApplications,
                        applications.length
                      )}{" "}
                      of {applications.length} Results
                    </span>
                  </div>

                  <div className="per-page-export-to-excel-rows-per-page-applications">
                    <div className="rows-per-page-applications">
                      <label style={{ marginRight: "10px" }}>
                        Rows per page
                      </label>
                      <select
                        value={responsesPerPageApplications}
                        onChange={handleRowsPerPageChangeApplications}
                      >
                        {[20, 50, 100, 500].map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="per-page-export-to-excel-applications">
                      <button
                        className="below-tab-button-applications"
                        onClick={handleExportCurrentPageToExcel}
                        title="Export all applications from the current page to Excel"
                      >
                        <span
                          style={{
                            marginRight: "6px",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          <FaFileExcel
                            style={{
                              fontWeight: "200",
                              fontSize: "12px",
                              color: "#28a745",
                            }}
                          />
                        </span>
                        Export Current Page to Excel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      case "Evaluations":
        return <div>Tab1 Content</div>;
      case "Evaluators":
        return <div>Tab2 Content</div>;
      default:
        return null;
    }
  };

  return (
    <div className="dashboard-homepage-allapplications">
      <ToastContainer position="bottom-right" />{" "}
      <aside className="sidebar-homepage-allapplications">
        <div className="logo-container-homepage-allapplications">
          <div className="logo-homepage-allapplications">
            <img
              src="/navbar/drishtilogo.jpg"
              alt="Logo"
              className="dristilogo-homepage-allapplications"
            />
          </div>
        </div>
        <div className="nav-container-homepage-allapplications">
          <nav className="nav-homepage-allapplications">
            <ul>
              <li>
                <Link to="/homepage">
                  <IoHomeOutline className="nav-icon-homepage-allapplications" />{" "}
                  Homepage
                </Link>
              </li>
              <li>
                <Link to="/form">
                  <CgNotes className="nav-icon-homepage-allapplications" />{" "}
                  Create Query Form
                </Link>
              </li>
              <li>
                <Link to="/cohorts">
                  <GrDocumentSound className="nav-icon-homepage-allapplications" />{" "}
                  Cohorts
                </Link>
              </li>
              <li>
                <Link to="/pipeline">
                  <IoIosLink className="nav-icon-homepage-allapplications" />{" "}
                  Pipeline
                </Link>
              </li>
              {/* <li>
                <Link to="/evaluator-dashboard">
                  <AiOutlineEye className="nav-icon-homepage-allapplications" />{" "}
                  Create Evaluation Form
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </aside>
      <main className="main-content-homepage-allapplications">
        <header className="header-homepage-allapplications">
          <span className="founder-homepage-allapplications">All Forms</span>
          <div className="profile-section-homepage-allapplications">
            <div className="user-info-homepage-allapplications">
              <span className="user-initials-homepage-allapplications">
                <img
                  src="/navbar/login.png"
                  alt="Login"
                  style={{ width: "40px" }}
                />
              </span>
              <div className="user-details-homepage-allapplications">
                <span className="user-name-homepage-allapplications">
                  {user.username}
                </span>
                <br />
                <span className="user-email-homepage-allapplications">
                  {user.email}
                </span>
              </div>
            </div>
            <button
              className="logout-button-homepage-allapplications"
              onClick={handleLogout}
              style={{ marginLeft: "20px", padding: "8px" }}
            >
              Logout
            </button>
          </div>
        </header>
        <section className="content-homepage-allapplications">
          <div className="title-rounds-container">
            <h3>{title}</h3>
            {rounds.length > 0 && (
              <div className="round-info-allapplications">
                {rounds.map((round) => (
                  <span
                    key={round.roundNumber}
                    className={`round-name-allapplications ${
                      currentRoundNumber === round.roundNumber
                        ? "active-round"
                        : ""
                    }`}
                    onClick={() => handleRoundChange(round.roundNumber)}
                  >
                    Round {round.roundNumber}
                  </span>
                ))}
              </div>
            )}
          </div>

          <div className="pipeline-tabs-allapplications">
            <button
              className={`tab-button-allapplications ${
                activeTab === "Applications" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Applications")}
            >
              Applications
            </button>
            {/* <button
              className={`tab-button-allapplications ${
                activeTab === "Evaluations" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Evaluations")}
            >
              Tab 1
            </button>
            <button
              className={`tab-button-allapplications ${
                activeTab === "Evaluators" ? "active" : ""
              }`}
              onClick={() => setActiveTab("Evaluators")}
            >
              Tab 2
            </button> */}
          </div>

          <div className="tab-content-allapplications">
            {renderTabContent()}
          </div>
        </section>
        <ConfirmationModal
          show={showConfirmationModal}
          onConfirm={handleConfirmStatusChange}
          onCancel={handleCancelStatusChange}
          newStatus={newStatus}
          comments={comments}
          setComments={setComments}
        />

        <EditColumnsModal
          show={showModal}
          handleClose={handleCloseModal}
          columns={allColumns} // Pass all columns
          handleSave={handleSaveColumns}
        />
      </main>
    </div>
  );
};

export default Applications;
