import {createSlice} from "@reduxjs/toolkit";

const initialState={
    superAdminRole:"",
    token:"",
    id:""
};

const superAdminSlice=createSlice({
    name:'superAdmin',
    initialState,
    reducers:{
        updateRole:(state,action)=>{
         
            state.superAdminRole=action.payload
        },
        updateToken:(state,action)=>{
         
            state.token=action.payload
        },
        updateId:(state,action)=>{
           
            state.id=action.payload
        },
        logoutUser:(state,action)=>{
        
            state.superAdminRole="";
            state.token="";
            state.id="";
        }

    }
});

export const superAdminAction=superAdminSlice.actions;
export const superAdminReducer=superAdminSlice.reducer;
export const superAdminSelector=(state)=>state.superAdminReducer.superAdminRole;
export const userToken=(state)=>state.superAdminReducer.token;
export const userId=(state)=>state.superAdminReducer.id;
